
export default {
  props: {
    width: {
      type: [Number],
      default: 1280,
    },
    height: {
      type: [Number],
      default: 52,
    },
  },
}
